const links = [
    {
        label: "Github",
        url: "https://github.com/michael-alb"
    },
    {
        label: "Twitter",
        url: "https://twitter.com/robesbr"
    },
    {
        label: "Youtube",
        url: "https://www.youtube.com/maikonmorais"
    },
    {
        label: "Unsplash",
        url: "https://www.maikonmorais.com.br"
    },
    {
        label: "Instagram",
        url: "https://www.instagram.com/michael-alb"
    },
]

export default links